// src/Home.js

import React,{ useState,useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';

import { FaTrash } from 'react-icons/fa';
import './styles.css';
import { useNavigate } from 'react-router-dom';
function FinalizarCompra() {
  const title = "Finalizar Compra - StreetFighter";
  const footerText = "© 2024 All rights reserved.";

  // Usando useState para armazenar o valor do input
  const [carrinho, setCarrinho] = useState(JSON.parse(localStorage.getItem("carrinho")));
  const [loading, setLoading] = useState(true);
  const [loadingBotao, setLoadingBotao] = useState(false);
  const [usuario, setUsuario] = useState(false);

  
  const navigate = useNavigate();
  const [enderecoRua, setEnderecoRua] = useState('');
  const [enderecoNumero, setEnderecoNumero] = useState('');
  const [enderecoBairro, setEnderecoBairro] = useState('');
  const [enderecoCidade, setEnderecoCidade] = useState('');
  const [enderecoEstado, setEnderecoEstado] = useState('');
  const [enderecoCep, setEnderecoCep] = useState('');
  const [enderecoObservacao, setEnderecoObservacao] = useState('');
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Fazendo a chamada para a API
    fetch('https://api.streetfightermotoparts.com.br/api/getUserFromToken', {
      method: 'GET', // O método pode ser 'GET' ou outro conforme sua API
      headers: {
        'Content-Type': 'application/json', // Tipo de conteúdo da requisição
        'Authorization': `Bearer ${token}` // Adiciona o token Bearer
      }
    })
      .then(response => response.json()) // Converte a resposta para JSON
      .then(data => {
        
        if(data.message){
          navigate("/carrinho");

        }
        
        setUsuario(data); // Armazena os dados da resposta
        setLoading(false); // Atualiza o estado para indicar que o carregamento terminou
      })
      .catch(error => {
        console.error('Erro ao buscar dados:', error);
        setLoading(false);
      });
  }, [token]); // Incluindo 'id' e 'navigate' no array de dependências
  var quantidadeTotalCarrinho = 0;
  carrinho.forEach(
    function(p){
      quantidadeTotalCarrinho += parseInt(p.quantidade);
    }
  );
  var totalDesconto = 0;
  carrinho.forEach(
    function(p){
      totalDesconto += parseInt(p.quantidade)*parseFloat(p.valorDesconto);
    }
  );
  var total = 0;
  carrinho.forEach(
    function(p){
      total += parseInt(p.quantidade)*parseFloat(p.valor);
    }
  );
  function moeda(valor){
    return new Intl.NumberFormat('pt-BR', { 
      style: 'currency', 
      currency: 'BRL' 
    }).format(valor)
  }
  
  function criarVenda(desconto){
    setLoadingBotao(true);
    const form = new FormData();
    if(desconto){
      form.append("valor", totalDesconto);
      form.append("observacaoPagamento", "Pix");

    }
    else{
      form.append("valor", total);
      form.append("observacaoPagamento", "Cartão");
    }

    form.append("enderecoRua", enderecoRua);
    form.append("enderecoNumero", enderecoNumero);
    form.append("enderecoBairro", enderecoBairro);
    form.append("enderecoCidade", enderecoCidade);
    form.append("enderecoEstado", enderecoEstado);
    form.append("enderecoCep", enderecoCep);
    form.append("enderecoObservacao", enderecoObservacao);
    var listaProduto = [];
    listaProduto = [];
    carrinho.forEach(function(p){
      if(desconto){
        listaProduto.push(
        {
          id:p.id,quantidade:p.quantidade,
          valor:p.valorDesconto,
          nome:p.nome,
          observacao:"Modelo: "+p.modelo+", "+p.frente+", Sensor: "+p.sensor
        });
      }
      else{
        listaProduto.push(
          {
            id:p.id,quantidade:p.quantidade,
            valor:p.valor,
            nome:p.nome,
            observacao:"Modelo: "+p.modelo+", "+p.frente+", Sensor: "+p.sensor
          });
      }
    });
    form.append("produtos", JSON.stringify(listaProduto));

    fetch('https://api.streetfightermotoparts.com.br/api/venda', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        // Não definir 'Content-Type' para FormData, o próprio browser define corretamente
      },
      body: form,
    })
    .then(response => response.json())  // Converte a resposta para JSON
    .then(data => {
      
      setLoadingBotao(false);
      if(!data.id){
        alert(JSON.stringify(data));
        
      }
      else{
        localStorage.removeItem("carrinho");
        localStorage.removeItem("quantidadeCarrinho");

        if(desconto){
          navigate("/sucessoCompra/pix:"+data.id);
          console.log('Resposta da API:', data);  // Lida com a resposta da API
        }
        else{navigate("/sucessoCompra/cartao:"+data.id);

        }
      }
    })
    .catch(error => {
      console.error('Erro ao fazer a requisição:', error);  // Lida com erros
    });

  }
  if(loading){
    return(
      <div>
        Carregando...
      </div>
    )
  }
  if(!carrinho || carrinho.length===0){
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content="Carrinho de compras." />
        </Helmet>
        <Header title={title} />
        <main className='cardCarrinho'>
          <h1 className='semProduto'>Sem produtos no carrinho<br/><a href="/">Voltar para tela inicial</a></h1>
        </main>
        <Footer footerText={footerText} />
      </div>
    );
  }
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Finalizar compra." />
      </Helmet>
      <Header title={title} />
      <main className='cardCarrinho'>
        <div className='tituloCarrinho'>
          Finalizar Compra
        </div>
        <div className='carrinhoInfo'>
            <div className='informacoesCompra'>
              <div>
                Nome: {usuario.name}
              </div>
              <div>
                CPF: {usuario.cpfCnpj}
              </div>
              <div>
                Telefone: {usuario.telefone}
              </div>
              <div>
                E-mail: {usuario.email}
              </div>
              
              <div className='separador separadorTotal'></div>
              <h2>Endereço</h2>
              <form className='formLogin'>
                <div>
                  Rua
                  <input
                    type="text"
                    placeholder='Rua'
                    className='form-control'
                    value={enderecoRua}
                    name='rua'
                    onChange={(e) => setEnderecoRua(e.target.value)}
                    required={true}
                  />
                </div>
                <div>
                  Número
                  <input
                    type="text"
                    placeholder='Número do endereço'
                    className='form-control'
                    value={enderecoNumero}
                    onChange={(e) => setEnderecoNumero(e.target.value)}
                    required={true}
                  />
                </div>
                <div>
                  Bairro
                  <input
                    type="text"
                    placeholder='Bairro'
                    className='form-control'
                    value={enderecoBairro}
                    onChange={(e) => setEnderecoBairro(e.target.value)}
                    required={true}
                  />
                </div>
                <div>
                  Cidade
                  <input
                    type="text"
                    placeholder='Cidade'
                    className='form-control'
                    value={enderecoCidade}
                    onChange={(e) => setEnderecoCidade(e.target.value)}
                    required={true}
                  />
                </div>
                <div>
                  Estado
                  <select
                    type="text"
                    placeholder='Estado'
                    className='form-control'
                    value={enderecoEstado}
                    onChange={(e) => setEnderecoEstado(e.target.value)}
                    required={true}
                  >
                    <option value="">Estado</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                    </select>
                </div>
                <div>
                  CEP
                  <input
                    type="text"
                    placeholder='88888-888'
                    className='form-control'
                    value={enderecoCep}
                    onChange={(e) => setEnderecoCep(e.target.value)}
                    required={true}
                  />
                </div>
                <div>
                  Observação
                  <input
                    type="text"
                    placeholder='Observação'
                    className='form-control'
                    value={enderecoObservacao}
                    onChange={(e) => setEnderecoObservacao(e.target.value)}
                    required={true}
                  />
                </div>
              </form>
            </div>
            <div className='resumoCarrinho'>
              <div className='linhaResumoCarrinho'>
                <h2>Resumo</h2>
                <div>
                  <span className='totalItensCarrinho'>
                  {quantidadeTotalCarrinho}
                  </span>
                  Itens
                </div>
              </div>
              <div className='linhaResumoCarrinho listaProdutoFinalizacao'>
              {
              carrinho.map((produto, indexArray) => (
                <div key={indexArray}>
                {produto.quantidade} - {produto.nome} - Sensor {produto.sensor}
                </div>
              ))}
              </div>
              <div className='linhaResumoCarrinho'>
                Subtotal
                <span className='totalItensCarrinho'>
                  {moeda(totalDesconto)}
                </span>
              </div>
              <div className='linhaResumoCarrinho'>
                Frete
                <span className='totalItensCarrinho'>
                  R$ 0,00
                </span>
              </div>
              <div className='separador'></div>
              <div className='linhaResumoCarrinho valorTotal'>
                Total no pix
                <span className='totalItensCarrinho'>
                  {moeda(totalDesconto)}
                </span>
              </div>
              <div className='linhaResumoCarrinho'>
                Ou  
                <span className='totalItensCarrinho'>
                  {moeda(total)} no cartão
                </span>
              </div>
              <div className='divBotoesPagamento'>
                <button className='botaoFinalizar' onClick={()=>{criarVenda(true)}} disabled={loadingBotao}>
                  {loadingBotao ? 'Carregando...' : 'Pagar no pix'}
                  
                </button>
                <button className='botaoFinalizar'  onClick={()=>{criarVenda(false)}}disabled={loadingBotao}>
                  {loadingBotao ? 'Carregando...' : 'Pagar no cartão'}
                  
                </button>
              </div>
            </div>
        </div>
      </main>
      <Footer footerText={footerText} />
    </div>
  );
}

export default FinalizarCompra;
