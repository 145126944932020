// src/Home.js

import React,{ useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';

import './styles.css';
import topoPneu from '../../assets/imagens/numeros-medidas-pneus.webp';
import { FaRegFilePdf } from 'react-icons/fa';
function Gabaritos() {
  
  const [largura, setLargura] = useState(0);

  const [perfil, setPerfil] = useState(0);
  const [alturaPerfil, setAlturaPerfil] = useState(0);

  const [aro, setAro] = useState(0);

  const [diametro, setDiametro] = useState(0);

  const [circuferencia, setCircuferencia] = useState(0);
  function calcularCircuferencia(){
    setAlturaPerfil((largura*perfil)/100);
    var raio = (((aro*25.4)/2)+alturaPerfil);
    
    var circuferenciaPneus =2*3.1416*raio;
    console.log(alturaPerfil);
    console.log(raio);
    console.log(2*3.1416*raio);
    setDiametro(Math.round(raio*2));
    setCircuferencia(Math.round(circuferenciaPneus));
  }
  useEffect(() => {
    if (largura && perfil && aro) { // Evita calcular se algum valor estiver vazio
        const alturaPerfilAtual = (largura * perfil) / 100;
        
        setAlturaPerfil(alturaPerfilAtual);
        const raio = (((aro * 25.4) / 2) + alturaPerfilAtual);
        const circunferenciaPneus = 2 * Math.PI * raio;

        setDiametro(Math.round(raio * 2));
        setCircuferencia(Math.round(circunferenciaPneus));
    }
    else{
      setDiametro(0);
      setCircuferencia(0);
      setAlturaPerfil(0);
    }
}, [largura, perfil, aro]);
  const title = "Street Fighter - Gabaritos";
  const footerText = "© 2024 All rights reserved.";

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Gabaritos dos painéis Street Fighter." />
      </Helmet>
      <Header title={title} />
      <main>
          <div className='tituloPagina'>
            <div>
              <a href='/'>Início</a> / Gabaritos
            </div>
            <div className='nomeTituloPagina'>
              GABARITOS
            </div>
          </div>
          <ul className='listaGabaritos'>
            <li>
              <a href="/gabarito/160.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SF160
              </a>
            </li>
            <li>
              <a href="/gabarito/CafeRacer.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                Cafe Racer
              </a>
            </li>
            <li>
              <a href="/gabarito/CBInferior.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                CB visão inferior
              </a>
            </li>
            <li>
              <a href="/gabarito/CBSuperior.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                CB visão superior
              </a>
            </li>
            <li>
              <a href="/gabarito/SF1.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SF1
              </a>
            </li>
            <li>
              <a href="/gabarito/SF2.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SF2
              </a>
            </li>
            <li>
              <a href="/gabarito/SF3.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SF3
              </a>
            </li>
            <li>
              <a href="/gabarito/SF110.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SF110
              </a>
            </li>
            <li>
              <a href="/gabarito/SF125.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SF125
              </a>
            </li>
            <li>
              <a href="/gabarito/SFBros.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SFBros
              </a>
            </li>
            <li>
              <a href="/gabarito/SFMini.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SFMini
              </a>
            </li>
            <li>
              <a href="/gabarito/SFZ.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SFZ
              </a>
            </li>
          </ul>

          <div className='calculadora-conversao'>
            
          <div className="titulo-rodas">
                  <h2>Calculadora de Circunferência</h2>
              </div>
          <div className="formulario">
        	
            
          <div className="medidas-topo">
            <img src={topoPneu} alt="Números do pneu"/>
          </div>
        
          <div className="medida-original">
            <div className="titulo-medida">
              <div colspan="4" className="tdTitulo">Medida do pneu</div>
            </div>
            <div className="opcvalores">
                <input  placeholder='Largura' onChange={(e) => setLargura(e.target.value)}/>
            </div>
            <div className="opcvalores">
                <input placeholder='Perfil' onChange={(e) => setPerfil(e.target.value)}/>
            </div>
            <div className="opcvalores">
                <input  placeholder='Aro' onChange={(e) => setAro(e.target.value)}/>
            </div>
          </div>
        

            </div>
            <div className="bloco-tamanho">
              <div className="bloco-img-original" >
                <div className="base-tamanho" >
                  <div className="pneu-original-diametro" >
                    <div className="texto-perfil texto-perfil-original">
                      Perfil: {alturaPerfil} mm
                      </div>
                    <div className="perfil-original" ></div>
                    <div className="texto-roda-original-diametro" >
                      <div>
                        Diâmetro: {diametro} mm
                      </div>
                      <div>
                      Circunferência: {circuferencia} mm
                      </div>
                    </div>
                    <div className="roda-original-diametro">

                    </div>
                  </div>
                </div>
                <div className="base-tamanho base-tamanho-largura" >
                  <div className="texto-largura texto-pneu-original-largura">
                    Largura: {largura} mm
                    </div>
                  <div className="pneu-original-largura tamanho-largura" >

                  </div>
                </div>
              </div>
          </div>
          {circuferencia ? (
              <h3>Sua Circunferência é {circuferencia} mm</h3>
            ) : (
              <h3></h3>
            )}
          </div>
      </main>
      <Footer footerText={footerText} />
    </div>
  );
}

export default Gabaritos;
