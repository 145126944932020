// src/Home.js

import React,{useState,useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';

import './style.css';
function SucessoCompra() {
  const title = "Street Fighter";
  const footerText = "© 2024 All rights reserved.";

  const { pagamento } = useParams();
  
  const [data, setData] = useState([]);
  const [linkPagarMe, setLinkPagarMe] = useState(false);
  const [pagar, setPagar] = useState(true);
  const [loading, setLoading] = useState(true);
  console.log(pagamento);
  function moeda(valor){
    return new Intl.NumberFormat('pt-BR', { 
      style: 'currency', 
      currency: 'BRL' 
    }).format(valor)
  }
  var eventoGetnet =false;
  function gerarLog(msg){
    var obj = {};
    obj.msg = msg;
    obj.idVenda = pagamento;
    try{
      fetch('https://streetfightermotoparts.com.br/wc-api/getnet-creditcard/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj) // Enviando os dados como JSON
      })
      .then(response => response.json()) // Converte a resposta para JSON
      .then(result => {
        console.log('Resultado da requisição POST:', result); // Resultado do servidor
      })
      .catch(error => {
        console.error('Erro ao fazer a requisição POST:', error);
      });
    } catch (err) {
      // Tratar erros de rede
      alert('Falha de conexão, tente novamente: ' + err.message);
    }
  }
  function clicouEmPagar(){
    var getnet = document.getElementById("getnet-checkout");
    if(getnet&&!eventoGetnet){
      getnet.addEventListener('load', ev => { 
        // Funções compatíveis com IE e outros navegadores
        var eventMethod = (window.addEventListener ? 'addEventListener' : 'attachEvent');
        var eventer = window[eventMethod];
        var messageEvent = (eventMethod === 'attachEvent') ? 'onmessage' : 'message';

        // Ouvindo o evento do loader
        eventer(messageEvent, function iframeMessage(e) {

          var data = e.data || '';
           
          switch (data.status || data) {
            // Corfirmação positiva do checkout.
            case 'success':
              console.log('Transação realizada.');
              gerarLog('Transação realizada.');
              setPagar(false);

              break;

            // Notificação de que o IFrame de checkout foi fechado a aprovação.
            case 'close':
              gerarLog('Checkout fechado.');
              console.log('Checkout fechado.'); 
              break; 

            // Notificação que um boleto foi registrado com sucesso 
            case 'pending': 
              gerarLog('Boleto registrado e pendente de pagamento. '+data.detail);
              console.log('Boleto registrado e pendente de pagamento'); 
              console.log(data.detail); 
              break;      

            // Notificação que houve um erro na tentativa de pagamento 
            case 'error': 
              console.log('Erro: '+data.detail.message); 
              console.warn(data.detail.message); 
              break;

            // Ignora qualquer outra mensagem 
            default:
              break;
          }
        }, false);
      }); 

      eventoGetnet =true;
    }
  }
  const splitPagamento = pagamento?pagamento.split(":"):[];
  useEffect(() => {
    
    // Fazendo a chamada para a API
    if(pagamento){
    fetch('https://api.streetfightermotoparts.com.br/api/venda/' + pagamento.split(":")[1]+"?tokenGetnet=1")
      .then(response => response.json()) // Converte a resposta para JSON
      .then(data => {
        
        setData(data); // Armazena os dados da resposta
        console.log(data);
        let partesNome = data.name.split(" ");

        let primeiroNome = partesNome[0];
        let sobrenome = partesNome.slice(1).join(" "); 
        let produtosConvertidos = data.produtos.map((produto, index) => {
          return {
              "name": produto.nome || "", // Usar "observacaoProduto" como "name"
              "description": produto.observacaoProduto || "", // Também podemos usar o mesmo campo para "description"
              "value": produto.valorProduto || 0, // "valorProduto" vai para "value"
              "quantity": produto.quantidadeProduto || 0, // "quantidadeProduto" vai para "quantity"
              "sku": `${index}-SKU-${produto.idProduto}` // Criar um SKU fictício com base no "idProduto"
          };
        });
      setLoading(false);
      var objPagarMe = JSON.parse(data.jsonPagarMe);
      setLinkPagarMe(objPagarMe.url);
        if(!objPagarMe.url){
         // Criando o script dinamicamente e adicionando-o ao DOM
          const script = document.createElement('script');
          script.src = "https://checkout.getnet.com.br/loader.js";
          script.async = true;
          script.setAttribute("data-getnet-sellerid", "c8bb20e0-7b3c-45ab-aa5e-98ca4e30dd72");
          script.setAttribute("data-getnet-token", data.tokenGetnet.token_type+ " " +data.tokenGetnet.access_token);
          script.setAttribute("data-getnet-amount", data.valor.toFixed(2));
          script.setAttribute("data-getnet-customerid", data.idUsuario);
          script.setAttribute("data-getnet-orderid", data.idVenda);
          script.setAttribute("data-getnet-button-class", "pay-button-getnet");
          if(pagamento.split(":")[0]==="cartao"){
        
            script.setAttribute("data-getnet-installments", "12");
          }
          else{
            script.setAttribute("data-getnet-payment-methods-disabled", '["credito"]');
          }
          script.setAttribute("data-getnet-customer-first-name", primeiroNome?primeiroNome.substring(0, 40):"");
          script.setAttribute("data-getnet-customer-last-name", sobrenome?sobrenome.substring(0, 80):"");
          script.setAttribute("data-getnet-customer-document-type", "CPF");
          script.setAttribute("data-getnet-customer-document-number", data.cpfCnpj?data.cpfCnpj.replace(/\D/g, '').substring(0, 15):"");
          script.setAttribute("data-getnet-customer-email", data.email);
          script.setAttribute("data-getnet-customer-phone-number", data.telefone?data.telefone.replace(/\D/g, '').substring(0, 15):"");
          script.setAttribute("data-getnet-customer-address-street", data.enderecoRua?data.enderecoRua.substring(0, 20):"");
          script.setAttribute("data-getnet-customer-address-street-number", data.enderecoNumero?data.enderecoNumero.replace(/\D/g, '').substring(0, 6):"");
          script.setAttribute("data-getnet-customer-address-complementary", data.enderecoObservacao?data.enderecoObservacao.substring(0, 12):"");
          script.setAttribute("data-getnet-customer-address-neighborhood", data.enderecoBairro? data.enderecoBairro.substring(0, 30):"");
          script.setAttribute("data-getnet-customer-address-city", data.enderecoCidade?data.enderecoCidade.substring(0, 20):"");
          script.setAttribute("data-getnet-customer-address-state", data.enderecoEstado);
          script.setAttribute("data-getnet-customer-address-zipcode", data.enderecoCep? data.enderecoCep.replace(/\D/g, ''):"");
          script.setAttribute("data-getnet-customer-country", "BR");
          script.setAttribute("data-getnet-items", JSON.stringify(produtosConvertidos));
          script.setAttribute("data-getnet-url-callback", "");
          //script.setAttribute("data-getnet-url-callback", "https://streetfightermotoparts.com.br/sucessoCompra/"+pagamento+":1");

          document.body.appendChild(script);

        
          return () => {
            // Remover o script quando o componente for desmontado
            document.body.removeChild(script);
          };
        }
      })
      .catch(error => {
        console.error('Erro ao buscar dados:', error);
      });
    }
    else{
      setLoading(false);
    }
  }, [pagamento]); // Incluindo 'id' e 'navigate' no array de dependências
  if(loading){
    return <div>Carregando</div>
  }
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Street Fighter Painéis automotivo." />
      </Helmet>
      <Header title={title} />
      <main>
        <div className='naoEncontrado'>
          <h1 className="text-center ">
            Obrigado por escolher a Street Fighter! 
            {pagamento ? (<>
              { splitPagamento[0]==="pix" ? (
                <>
                  <br/><br/>
                  Faça o pix no valor de {moeda(data.valorDesconto)} para a chave pix<br/>(31) 9 9104-9112
                  <br/>
                  {pagar ? (
                    <>
                    ou
                    <br/>
                    <div className='botaoPagar'>
                      {linkPagarMe? <a  className='pay-button' href={linkPagarMe} >Pagar agora</a>:(<button className='pay-button-getnet' onClick={clicouEmPagar} >Pagar agora</button>)}
                      
                      </div>
                    </>
                  ):(<><br/>Seu produto chegará em breve!</>)}
               </>):(
                <>
                  {pagar ? (
                    <>
                    <div className='botaoPagar'>
                    {linkPagarMe? <a  className='pay-button' href={linkPagarMe} >Pagar agora</a>:(<button className='pay-button-getnet' onClick={clicouEmPagar} >Pagar agora</button>)}
                    </div>
                    </>
                  ):(<><br/>Seu produto chegará em breve!</>)}
                </>
            )}
            </>):(
          <><br/>Seu produto chegará em breve!</>
            )}
          </h1>
          
          <a href="/" className="link_404">Ir para início</a>
        </div>
      </main>
      <Footer footerText={footerText} />
    </div>
  );
}

export default SucessoCompra;

//<Carousel images={images} />