// src/Produto.js
import React from 'react';
import './Produto.css';
import { Link } from 'react-router-dom';
const Produto = ({ produto }) => {


  return (
    <Link className='produto' to={produto.link}>
      <div className="infoProduto">
        <img className="imagemProduto" src={produto.imagem} alt={"Imagem do "+produto.nomeProduto}/>
        <div className="fundoPreto">
          <div className="nomeProduto">
            {produto.nomeProduto}
          </div>
          <div className="precoProduto">
          {produto.status===1? (<span>{produto.valorProduto}
          <span className='noPix'>NO PIX</span></span>):(<span>Indisponível</span>)}
            
          </div>
        </div>
      </div>
    </Link>
  );
};


export default Produto;
